<template max-width="10">
  <div class="px-4">
    <v-card class="d-flex align-center rounded-lg ma-4 pa-2" flat>
      <v-row>
        <v-col class="py-0 my-0" cols="12" md="6" sm="12">
          <v-switch
            color="success"
            v-model="switch1"
            :label="$t('mail.ViewArchivedMail')"
            class="mx-1"
            @change="getMails"
          ></v-switch>
        </v-col>
        <v-col
          :class="$i18n.locale == 'ar' ? 'text-left mt-1' : 'text-right mt-1'"
          cols="12"
          md="6"
          sm="12"
        >
          <v-btn color="error" outlined class="mx-1 mt-1" @click="mailsDelete"
             :disabled="!rowKeysSelected.length>0"
             :loading="loadingDelete">
            <v-icon left> mdi-delete </v-icon>
            {{ $t("delete") }}
          </v-btn>
          <v-btn
            color="success"
            outlined
            class="mx-1 mt-1"
            @click="archiveMail"
            :loading="loadingArchiveMail"
            v-if="!switch1"
               :disabled="!rowKeysSelected.length>0"
          >
            <v-icon left> mdi-archive-arrow-down </v-icon>
            {{ $t("mail.ArchiveMail") }}
          </v-btn>
          <v-btn
            color="success"
            outlined
            class="mx-1 mt-1"
            :loading="loadingArchiveMail"

            @click="unArchiveMail"
               :disabled="!rowKeysSelected.length>0"
            v-else
          >
            <v-icon left> mdi-archive-arrow-up </v-icon>
            {{ $t("mail.CancelArchivingMail") }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card>
      <v-row class="px-4">
      <v-col cols="12" md="12" sm="12">
        <v-text-field
          v-model="search"
          append-icon="mdi-magnify"
          :label="$t('writeWhatYouSearch')"
          outlined
          dense
          hide-details
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-row  style="margin-bottom: 60px;">
      <v-col cols="12" md="12" sm="12">
    <v-card
      class="d-flex align-center rounded-lg mx-4 pa-2 pb-0"
      flat
    >
     <v-card-text>
            <v-data-table
              v-model="rowKeysSelected"
              :headers="_headers"
              :items="mails"
              :search="search"
              item-key="guid"
              show-select
              width="100%"
              :loading="loadingData"
            >
          <template v-slot:[`item.toName`]="{ item }">
           <v-chip v-if="item.to.length == 1">{{
            item.to[0].toName
          }}</v-chip>

          <v-menu top offset-y v-else rounded="t-xl b-xl" dark>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                color="primary"
                rounded
                elevation="0"
                dark
                v-bind="attrs"
                v-on="on"
              >
                {{
                  item.to.length > 10
                    ? `${item.collectionCount} ${$t("mail.Person")}`
                    : `${item.collectionCount}  ${$t("mail.Persons")}`
                }}
              </v-btn>
            </template>
            <v-list class="my-0 pa-2">
              <v-list-item
                v-for="(item, index) in item.to"
                :key="index"
                class="my-0 py-1"
              >
                <v-list-item-title class="my-0 py-0">
                  <v-chip small class="my-0 py-0">
                    {{ `${item.toName}  -  ${item.toCode}` }}
                  </v-chip>
                </v-list-item-title>
              </v-list-item>
              <v-list-item v-if="item.collectionCount > 10">
                <v-list-item-title class="my-0 py-0">
                  <v-chip small class="my-0 py-0">
                    {{
                      ` + ${item.collectionCount - 10}   ${$t(
                        "mail.Others"
                      )} `
                    }}
                  </v-chip>
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
          </template>

              <template v-slot:[`item.attachedFileUrl`]="{ item }">
                <v-tooltip top>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      v-if="item.attachedFileUrl"
                      icon
                      color="green"
                      @click="installFile(item)"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon>mdi-link-variant</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t("mail.DownloadTheAttachment") }}</span>
                </v-tooltip>
              </template>

              <template v-slot:[`item.datex`]="{ item }">
                <div>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <p v-bind="attrs" v-on="on">
                        {{ item.datex2 }}
                      </p>
                    </template>
                    <span>{{ item.datex }}</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:[`item.fullSectionPath`]="{ item }">
                <div>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <p v-bind="attrs" v-on="on">
                        <v-icon>mdi-information-outline</v-icon>
                      </p>
                    </template>
                    <span>{{ item.fullSectionPath }}</span>
                  </v-tooltip>
                </div>
              </template>
              <template v-slot:[`item.actions`]="{ item }">
                <div>
                  <v-tooltip top>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn icon color="primary" @click="showMail(item)" v-bind="attrs" v-on="on">
                        <v-icon>mdi-eye</v-icon>
                      </v-btn>
                    </template>
                    <span>{{ $t("Preview") }}</span>
                  </v-tooltip>
                </div>
              </template>
            </v-data-table>
          </v-card-text>


    </v-card>
      </v-col>
    </v-row>

    <v-fab-transition class="">
      <v-btn
        color="secondary"
        fab
        large
        elevation="6"
        fixed
        bottom
        :left="$vuetify.rtl"
        :right="!$vuetify.rtl"
        class="v-btn--example"
        :class="$store.state.drawerMessage ? 'ml-16' : ''"
        :style="!$vuetify.rtl?'right:80px':''"
        @click="openDialog"
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>
    <SendMailComp
      ref="childComponent"
      v-on:send-result-values="getMails"
    ></SendMailComp>

  </div>
</template>

<script>
import moment from "moment";
import SendMailComp from "../../components/mails/sendMail.vue";


export default {
  components: {
    SendMailComp
  },
  data() {
    return {
      search: "",
      loadingData: false,
      loadingDelete: false,
      loadingArchiveMail: false,
      switch1: 0,
      rtlEnabled: true,
      allMode: "allPages",
      checkBoxesMode: "onClick",
      mails: [],
      rowKeysSelected: [],
       headers: [
        {
          text: this.$t("mail.subjectName"),
          value: "subjectName",
          show: true
        },
        {
          text: this.$t("mail.to"),
          value: "toName",
          show: true
        },
        {
          text: this.$t("mail.title"),
          value: "title",
          show: true
        },
        {
          text: this.$t("mail.Attachments"),
          value: "attachedFileUrl",
          show: true
        },
        {
          text: this.$t("date"),
          value: "datex",
          show: true
        },
        {
          text: this.$t("mail.Section"),
          value: "fullSectionPath",
          show: this.isInRole(2)
        },
        {
          text: this.$t("mail.actions"),
          value: "actions",
          show: true
        }
      ]
    };
  },
  computed: {
      _headers() {
      return this.headers.filter(x => x.show);
    }
  },
  methods: {
    openDialog() {
      this.$refs.childComponent.setValue();
    },

    archiveMail() {
         if (!this.rowKeysSelected || this.rowKeysSelected.length == 0) {
            return;
      }
      this.loadingArchiveMail = true;
      const data = {
        archiving: true,
        mailType: 1,
        mailGuids: this.rowKeysSelected.map(m => m.guid),
      };
      this.axios
        .post("Mails/Archiving", data)
        .then(() => {
          this.getMails();
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
          this.loadingArchiveMail = false;
        });
    },
    unArchiveMail() {
         if (!this.rowKeysSelected || this.rowKeysSelected.length == 0) {
            return;
      }
      this.loadingArchiveMail = true;

      const data = {
        archiving: false,
        mailType: 1,
        mailGuids: this.rowKeysSelected.map(m => m.guid),
      };
      this.axios
        .post("Mails/Archiving", data)
        .then(() => {
          this.getMails();
        })
        .catch(() => {
          this.$swal.fire({
            title: this.$t("AnErrorOccurredDuringTheProcess"),
            text: "",
            icon: "error",
            confirmButtonText: this.$t("close"),
          });
        })
        .finally(() => {
      this.loadingArchiveMail = false;
        });
    },

    mailsDelete() {
         if (!this.rowKeysSelected || this.rowKeysSelected.length == 0) {
            return;
      }
      this.$swal
        .fire({
          title: this.$t("AreYouSureAboutTheOperation"),
          text: this.$t("TheMessagesReadByTheRecipientCannotBeDeleted"),
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#d33",
          confirmButtonText: this.$t("yes"),
          cancelButtonText: this.$t("no"),
        })
        .then((result) => {
          if (result.value) {
      this.loadingDelete = true;

            this.axios
              .post("Mails/Delete", this.rowKeysSelected.map(m => m.guid))
              .then(() => {
                this.getMails();
              })
              .catch(() => {
                this.$swal.fire({
                  title: this.$t("mail.ReadMessagesCannotBeDeleted"),
                  text: "",
                  icon: "error",
                  confirmButtonText: this.$t("close"),
                });
              })
              .finally(() => {
                 this.loadingDelete = false;
              });
          }
        });
    },

    showMail(data) {
      this.$router.push({
        path: `showMail=${data.guid}&1`,
        params: { id: data.guid, type: 1 },
      });
    },
    installFile(data) {
                  window.open(`${data.attachedFileUrl}`);
    },
    getMails() {
      this.loadingData = true;
      const archiving = this.switch1 ? 1 : 0;
      this.axios
        .get(`Mails/Get?mailType=1&archiving=${archiving}`)
        .then((res) => {
          this.rowKeysSelected = [];
          this.mails = res.data.data;
          this.mails.forEach((e) => {
            e.datex2 = moment(e.datex).fromNow();
            e.datex = moment(e.datex).format("YYYY/MM/DD - h:mm a ");
           // e.title= e.title.length>100?e.title.substring(0,99):e.title
          });
        })
        .catch(() => {})
        .finally(() => {
          this.loadingData = false;
        });
    },
  },
  created() {
    this.getMails();
  },
};
</script>
<style lang="scss" scoped>
.dx-datagrid .dx-row > td {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
.dx-htmleditor .dx-htmleditor-toolbar-wrapper:first-child {
  border-bottom: 1px solid #909090;
}
.dx-htmleditor-content img {
  vertical-align: middle;
  padding-right: 10px;
}

.dx-htmleditor-content table {
  width: 50%;
}

.options {
  padding: 20px;
  background-color: rgba(191, 191, 191, 0.15);
  margin-top: 20px;
}

.caption {
  font-size: 18px;
  font-weight: 500;
}
.dx-htmleditor {
  border-color: #7c7c7c;
}
.option {
  margin-top: 10px;
}
.v-list-item {
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
  -ms-flex: 1 1 100%;
  flex: 1 1 100%;
  letter-spacing: normal;
  min-height: 1px;
  outline: none;
  padding: 0px;
  position: relative;
  text-decoration: none;
}

</style>
